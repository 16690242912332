
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import useNavigation from '../composables/useNavigation';
import QrScannerCamera from '../components/QrScannerCamera.vue';
import { loading, toast } from '../utils/frameworkFeatures';
import { AppErrorState } from '@/config/app.config';
import { useMainStore } from '@/stores/main.store';
import { parseQrUrl } from '@/utils/utils';
import { initApp } from '@/utils/app.utils';

export default defineComponent({
    name: 'QrScanner',
    components: {
        QrScannerCamera,
    },
    setup() {
        const router = useRouter();
        const store = useMainStore();
        let qrLoaded = false;
        const { goHome } = useNavigation();
        let loadingInstance: any;

        function onQrData(data: string) {
            if (/turf\..{3}/.test(data)) {
                window.location.href = data;
                return;
            }

            const resp = parseQrUrl(data);

            if (resp.error) {
                toast({ message: resp.error });
            } else {
                initApp(resp)
                    .then(() => {
                        goHome();
                    })
                    .catch(error => {
                        toast({ message: error.message });
                    });
            }
        }

        onMounted(() => {
            store.$patch({ tempDeskId: '' }); // reset the spaceId on the scanned QR if any
        });

        onUnmounted(() => {
            loadingInstance && loadingInstance.dismiss();
        });

        function onQrLoading() {
            setTimeout(async () => {
                if (!qrLoaded) {
                    loadingInstance = await loading();
                }
            }, 200); // lets give qrScanner a couple of miliseconds
        }
        
        function onQrReady() {
            qrLoaded = true;
            loadingInstance && loadingInstance.dismiss();
        }

        return {
            router,
            AppErrorState,
            goHome,
            onQrLoading,
            onQrReady,
            store,
            onQrData,
        };
    },
});
