
import { TrackJS } from 'trackjs';
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import jsQR, { QRCode } from 'jsqr';
import { canvasDrawBox } from '@/utils/canvas.utils';

export default defineComponent({
    name: 'QrScannerCamera',
    emits: ['on-ready', 'on-loading', 'on-data'],
    setup(props, { emit }) {
        const canvasElRef: any = ref(null);
        const errorMsg = ref('');
        const video = document.createElement('video');
        let animationFrame: number = null;
        let isQrReaderLoaded = false;
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        //@ts-ignore-next-line
        const _requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame as RequestAnimation || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame as RequestAnimation;

        function stopCamera(mediaStream: MediaStream) {
            if (mediaStream) {
                mediaStream.getTracks().map(t => t.stop());
            }
        }

        function requestAnimationFrame(callback: FrameRequestCallback): number {
            if (animationFrame) {
                cancelAnimationFrame(animationFrame);
            }
            return _requestAnimationFrame(callback);
        }

        function startQRScanner(): void {
            const canvas = canvasElRef.value.getContext('2d');

            navigator.mediaDevices
                .getUserMedia({ video: { facingMode: 'environment' } })
                .then((stream: MediaStream) => {
                    function tick() {
                        if (video.readyState === video.HAVE_ENOUGH_DATA && canvasElRef.value) {
                            if (!isQrReaderLoaded) {
                                isQrReaderLoaded = true;
                                emit('on-ready');
                            }
                            canvasElRef.value.hidden = false;
                            canvasElRef.value.height = video.videoHeight;
                            canvasElRef.value.width = video.videoWidth;
                            canvas.drawImage(video, 0, 0, canvasElRef.value.width, canvasElRef.value.height);

                            const imageData = canvas.getImageData(0, 0, canvasElRef.value.width, canvasElRef.value.height);
                            const code: QRCode = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: 'dontInvert' });

                            if (code) {
                                canvasDrawBox(canvas, code.location.topLeftCorner, code.location.topRightCorner, code.location.bottomLeftCorner, code.location.bottomRightCorner, '#FF3B58');
                                emit('on-data', code.data);
                            }
                        }
                        requestAnimationFrame(tick);
                    }

                    video.srcObject = stream;
                    video.setAttribute('playsinline', 'true'); // required to tell iOS safari we don't want fullscreen
                    video.play();
                    animationFrame = requestAnimationFrame(tick);
                })
                .catch(err => {
                    const error = err && err.message;
                    const iosCameraDisableError = err && err.name;

                    TrackJS.console.error(`Failed to start QR Scanner: ${JSON.stringify({
                        error
                    })}`, 'startQRScanner');


                    if (error === 'Permission denied' || iosCameraDisableError === 'NotAllowedError') {
                        errorMsg.value = 'To use this feature, you must enable the camera access on your device.';
                    }
                });
        }

        onMounted(() => {
            startQRScanner();
            isQrReaderLoaded = false;
            emit('on-loading');
        });

        onUnmounted(() => {
            cancelAnimationFrame(animationFrame);
            stopCamera(video.srcObject as MediaStream);
        });

        return {
            canvasElRef,
            errorMsg,
        };
    },
});
