export function canvasDrawLine(canvas: any, begin: any, end: any, color: string) {
    canvas.beginPath();
    canvas.moveTo(begin.x, begin.y);
    canvas.lineTo(end.x, end.y);
    canvas.lineWidth = 4;
    canvas.strokeStyle = color;
    canvas.stroke();
}

/**
 * draw a lined square in a canvas
 * @param canvas 
 * @param topLeft 
 * @param topRight 
 * @param bottomLeft 
 * @param bottomRight 
 * @param color line color
 */
export function canvasDrawBox(canvas: any, topLeft: Point, topRight: Point, bottomLeft: Point, bottomRight: Point, color: string) {
    canvasDrawLine(canvas, topLeft, topRight, color);
    canvasDrawLine(canvas, topRight, bottomRight, color);
    canvasDrawLine(canvas, bottomRight, bottomLeft, color);
    canvasDrawLine(canvas, bottomLeft, topLeft, color);
}

